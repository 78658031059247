<template>
<v-sheet height="100vh" tile class="grey darken-3">
	<v-container fill-height fluid>
		<v-row class="grey darken-3 text-center" justify="center" align="center">
			<v-col class="py-5" cols="12" sm="10" md="10">
				<v-card dark elevation="10">
					<v-card-text>
						<v-container>
						<v-img
							src="@/assets/logo-name-white.png"
							contain
							height="40"
						/>
							<span class="white--text">SIGNUP FORM</span>

							<v-form ref="form" v-model="valid">
								<v-row>
									<v-col cols="12" sm="6">
										<v-text-field
												prepend-icon="mdi-account-reactivate-outline"
												label="Name"
												v-model="user.name"
												:rules="[rules.required]"
										/>
										<v-text-field
												prepend-icon="mdi-account-reactivate-outline"
												label="Last name"
												v-model="user.lastName"
												:rules="[rules.required]"
										/>
										<v-text-field
												prepend-icon="mdi-email-outline"
												label="E-mail"
												v-model="user.email"
												:rules="[rules.required, rules.email]"
										/>
										<v-text-field
												prepend-icon="mdi-office-building-outlinee"
												label="Company"
												v-model="user.company"
												:rules="[rules.required]"
										/>
										<v-text-field
												prepend-icon="mdi-cards-variant"
												label="Department"
												v-model="user.department"
										/>
										<v-text-field
												type="tel"
												prepend-icon="mdi-phone"
												label="Phone"
												v-model="user.phone"
												:rules="[rules.required]"
										/>
										<v-textarea
												dense
												outlined
												prepend-icon="mdi-map-marker-radius-outline"
												v-model="user.address"
												label="Street address"
												:value="user.address"
												rows="2"
										/>
									</v-col>
									<v-col cols="12" sm="6">

										<v-text-field
												prepend-icon="mdi-mailbox-outline"
												label="Postal Code"
												v-model="user.zip"
												:rules="[rules.required]"
										/>
										<v-text-field
												prepend-icon="mdi-home-city-outline"
												label="City"
												v-model="user.city"
										/>
										<v-text-field
												prepend-icon="mdi-city-variant-outline"
												label="State"
												v-model="user.state"
										/>
										<v-text-field
												prepend-icon="mdi-flag-outline"
												label="Country"
												v-model="user.country"
										/>
										<div class="my-5">
											<v-divider />
										</div>
										<v-text-field
											filled
											type="password"
											:rules="[rules.required]"
											prepend-icon="mdi-shield-lock-outline"
											v-model="pass1"
											label="Password"
											persistent-hint
											:type="showPass1 ? 'text' : 'password'"
											:append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="showPass1 = !showPass1"
										/>
										<v-text-field
											filled
											prepend-icon="mdi-shield-lock-outline"
											v-model="pass2"
											:rules="[(pass1 === pass2) || 'Passwords do not match']"
											label="Confirm Password"
											:type="showPass2 ? 'text' : 'password'"
											:append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="showPass2 = !showPass2"
										/>
									</v-col>
								</v-row>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions class="grey darken-4">

							<v-btn class="grey--text" text @click="cancel"> Return to Login</v-btn>
							<v-spacer />
							<v-btn :loading="saving" @click.stop="validate()" color="primary" large> <v-icon class="mr-3" color="white">mdi-check-circle</v-icon> REGISTER NOW</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<!-- <Notification /> -->
			<v-snackbar
					top
					right
					:timeout="alert.timeout"
					:color="alert.color"
					v-model="alert.show">
				{{ alert.message }}
			</v-snackbar>
		</v-row>
	</v-container>
</v-sheet>

</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'Register',
		data: ()=>({
			showPass1: false,
			showPass2: false,
			pass1: '',
			pass2: '',
      passwordLabel: '',
      saving: false,
      editedIndex: -1,
      valid: true,
      user: {
				_id: '',
        name: '',
        lastName: '',
        company: '',
        department: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        password: '',
      },
      rules: {
        required: value => !!value || 'Item is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Must be valid e-mail'
        },
      },

		}),
		methods: {
			...mapActions('userModule',['createUser']),
			validate () {
				if (this.$refs.form.validate()) {
					this.register()
				}
			},
			async register(){
				let me=this
				this.saving = true
				this.user.password = this.pass1.trim()
				this.user.email = this.user.email.trim().toLowerCase()
				let resp = await this.createUser(me.user)

				// let response = await axios.post('user/login',{
        //   email: me.email, password: me.password
        // })
        // this.$store.dispatch("authModule/saveToken",response.data.tokenReturn);

				this.clearCreate()
				this.$router.push({name: 'Home'});

			},

			clearCreate(){
				this.saving = false;
				this.pass1 = ''
				this.pass2 = ''
				this.$refs.form.reset()
				this.user = {
					_id: '',
					name: '',
					lastName: '',
					company: '',
					department: '',
					email: '',
					phone: '',
					address: '',
					city: '',
					state: '',
					zip: '',
					country: '',
					password: '',
				}
			},
			cancel(){
				this.clearCreate()
				this.$router.push({name: 'Home'})
			},

		},
		computed: {
			...mapState(['alert']),
		}

	}
</script>

<style lang="scss" scoped>

</style>